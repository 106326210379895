
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































.careers {
  ::v-deep {
    .list__item__category {
      flex: 0 0 20rem;
    }
  }
}

.careers__content {
  ::v-deep {
    /* stylelint-disable-next-line selector-class-pattern */
    .cpt-duoH:first-of-type {
      display: none;
    }
  }
}
